import {Auth} from "aws-amplify";
import axios from "axios";

export const authenticated_request = async (
  method,
  path,
  data,
  contentType
) => {
  const currentSession = await Auth.currentSession();
  return await axios({
    method: method,
    url: process.env.REACT_APP_BACKEND_URL + path,
    headers: {
      "Content-Type": contentType,
      Authorization: currentSession.getAccessToken().jwtToken,
    },
    data: data,
  });
};
