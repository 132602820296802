import {useReactMediaRecorder} from "react-media-recorder";
import {authenticated_request} from "../utils";
import "./recorder.css";
import {
  faMicrophone,
  faStop,
  faUpload,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import Button from "./Button";

const AudioRecorder = ({snippet, user, setUploadCounter, uploadCounter}) => {
  const {status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl} =
    useReactMediaRecorder({video: false});

  const audioUpload = async () => {
    const audioBlob = await fetch(mediaBlobUrl).then((r) => r.blob());
    const audiofile = new File([audioBlob], "audiofile.wav", {
      type: "audio/webm",
    });
    let data = new FormData();
    data.append("text", snippet.text);
    data.append("user", user.username);
    data.append("id", snippet.id);
    data.append("file", audiofile, "recording.wav");

    const response = await authenticated_request(
      "post",
      "/save-file",
      data,
      "multipart/form-data"
    );
    if (response.status === 200) {
      clearBlobUrl();
    }
    setUploadCounter(uploadCounter++);
    window.location.reload();
  };

  const AudioControls = () => {
    if (status === "recording") {
      return <Button onClick={stopRecording} icon={faStop} text="Stop" />;
    } else if (status !== "stopped") {
      return (
        <Button onClick={startRecording} icon={faMicrophone} text="Record" />
      );
    }
    if (status === "stopped") {
      return (
        <div className="media-container">
          <div className="playback-container">
            <audio src={mediaBlobUrl} controls />
          </div>
          <div className="action-container">
            <Button onClick={clearBlobUrl} icon={faX} text="Clear" />
            <Button onClick={audioUpload} icon={faUpload} text="Upload" />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="recorder-container">
      <AudioControls />
    </div>
  );
};

export default AudioRecorder;
