import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {ThemeProvider, createTheme} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {Authenticator} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {Amplify} from "aws-amplify";

Amplify.configure({
  Auth: {
    region: "eu-west-2",
    userPoolId: "eu-west-2_sLvIYCwZx",
    userPoolWebClientId: "6q2qqk6ofs5578ej9mik60usso",
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Authenticator hideSignUp={true}>
    {({signOut, user}) => (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <App user={user} />
      </ThemeProvider>
    )}
  </Authenticator>
);
