import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function Button({onClick, icon, text}) {
  return (
    <a
      class="no-underline near-white bg-animate bg-near-black hover-bg-gray inline-flex items-center ma2 tc br2 pa3 pointer"
      onClick={onClick}
    >
      <FontAwesomeIcon icon={icon} />

      <span className="f6 ml3 pr2">{text}</span>
    </a>
  );
}
