import React, {useState, useEffect} from "react";
import "./App.css";
import logo from "./logo.webp";
import "tachyons/css/tachyons.min.css";
import AudioRecorder from "./components/recorder";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {authenticated_request} from "./utils";
import Snackbar from "./components/Snackbar";
function FeedbackDialog(props) {
  const {onClose, selectedValue, open, snippet, user} = props;
  const [reason, setReason] = React.useState("");
  const handleClose = () => {
    onClose(selectedValue);
  };

  const postIssue = async () => {
    await authenticated_request("post", "/feedback", {
      snippetText: snippet.text,
      snippetID: snippet.id,
      reason: reason,
      user: user.attributes.email,
    });
    handleClose();
  };
  return (
    <Dialog
      className="pa2"
      maxWidth="lg"
      fullWidth="lg"
      onClose={handleClose}
      open={open}
    >
      <DialogTitle>What's wrong?</DialogTitle>
      <div className="flag-form">
        <h4>Tell us what's wrong with:</h4>
        <p style={{fontStyle: "italic"}}>"{snippet.text}"</p>
        <TextField
          id="outlined-basic"
          label="Reason"
          variant="outlined"
          multiline="true"
          style={{width: "100%"}}
          onChange={(e) => {
            setReason(e.target.value);
          }}
        />
        <div className="button-group">
          <Button color="success" onClick={postIssue}>
            {" "}
            Submit
          </Button>
          <Button onClick={handleClose}> Cancel</Button>
        </div>
      </div>
    </Dialog>
  );
}

export default function App({user}) {
  const [snippet, setSnippet] = useState({});
  const [open, setOpen] = React.useState(false);
  const [howTo, setHowTo] = React.useState(localStorage.getItem("howTo"));
  const [uploadCounter, setUploadCounter] = React.useState(0);

  const getSnippet = async () => {
    authenticated_request("get", "/text-snippet").then((response) => {
      setSnippet({text: response.data.snippet, id: response.data.id});
    });
  };
  useEffect(() => {
    getSnippet();
  }, [uploadCounter]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const howToStatus = () => {
    localStorage.setItem("howTo", false);
    setHowTo(false);
  };

  const handleClose = (value) => {
    setOpen(false);
  };
  return (
    <article className="avenir">
      <div className="vh-100 dt w-100 tc  white cover bgImage">
        <div className="dtc v-mid">
          <header className="fixed white-70 tc pa1 mb5 top-0 tc w-100 pa2">
            <h2 className="f6 fw1 ttu tracked mb2 lh-title"></h2>
            <img src={logo} class=" measure" alt="Vaarst"></img>
            <h3 class="f2 fw4 avenir">Recorder</h3>
          </header>
          {howTo === null ? (
            <article class="mw9 center ph1 ph5-ns br2 pv3 near-white b--orange ba">
              <h2 class="fw3 f4 lh-copy mt0 mb4">
                <div className="tl">
                  Hello there, <br /> We want to gather audio data for
                  fine-tuning openAI's{" "}
                  <a
                    className="orange"
                    href="https://github.com/openai/whisper"
                  >
                    whisper
                  </a>{" "}
                  model so it works better for survey-speech. If you can help us
                  with a few minutes of recodings, that would be great :) <br />
                  For recording a sentence, hit the record button, read the
                  sentence and then hit stop (you browser might ask you for
                  recording permissions which you need to provide). After
                  reading the sentence, you can listen to the recording. If you
                  are happy, hit the upload button, if not, you can delete the
                  recording and try again. If you find mistakes in the sentences
                  or something else wrong with it, plase notify us through the
                  link at the bottom of the page. If you want to skip a sentence
                  (for instance because you don't know how to pronounce a word),
                  just reload the page (press f5). <br />
                  Please try to speak in a natural way. This means adding some
                  pauses, some "ehm"s, emphasizing certain words etc. You can
                  try out different accents, speed, loudness etc in order to
                  increase the diversity of the recordings. It does not matter
                  if you are in a noisy environment, as long as your voice can
                  be understood. <br />
                  If you have any questions, feedback, or want to raise an
                  issue, message Aljoscha on Slack or send an{" "}
                  <a className="orange" href="mailto:support@vaarst.com">
                    email
                  </a>
                  . <br />
                  Thank you! <br />
                </div>
                Sample survey audio file: <br />
                <audio className="pv3 tc" controls>
                  <source
                    src="https://vaarst-platform-backend-static.s3.eu-west-2.amazonaws.com/static/0a354e43-9b4f-4698-966a-a8debcf291a5.mp3"
                    type="audio/mpeg"
                  />
                  Your browser does not support the audio element.
                </audio>
                <br />
              </h2>
              <a
                className="f7 center br-pill bg-black no-underline washed-green ba b--black grow pv2 ph3 dib"
                href="#"
                onClick={howToStatus}
              >
                Begin
              </a>
            </article>
          ) : (
            <>
              <div class="pa4">
                <blockquote class=" f2 fw4 i white-90">
                  <p class="i ">"{snippet.text}"</p>
                </blockquote>
              </div>
              <blockquote className="ph0 mh0 measure f4 lh-copy center">
                <AudioRecorder
                  snippet={snippet}
                  user={user}
                  setUploadCounter={setUploadCounter}
                  uploadCounter={uploadCounter}
                />
              </blockquote>
            </>
          )}
        </div>
      </div>
      <a
        className="fixed bottom-0 pa3 dim link pointer"
        onClick={handleClickOpen}
      >
        Something wrong with the text?
      </a>
      <FeedbackDialog
        onClose={handleClose}
        // selectedValue={selectedValue}
        open={open}
        snippet={snippet}
        user={user}
      />

      <Snackbar />
    </article>
  );
}
